import React, { useState, useEffect } from 'react';
import './index.scss'
import { useNavigate, useLocation } from 'react-router-dom';

import Logo from '../../assets/logo-final.png'
import IconLoading from '../../assets/loading.png'
import BackButton from '../../assets/back-btn.png'
import Footer from '../Footer';


const WithLoading = (children: JSX.Element) => {

    const [isLoading, seIsLoading] = useState(true)

    let location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            seIsLoading(false)
        }, 1700)
    }, [])

    return (
        <>
            <div className="fade-in">
                <header className="header">
                    <a className="btn-back" onClick={() => { window.location.href.includes('resultado') ? navigate('/') : navigate(-1) }}>
                        <img src={BackButton} alt="img-back" />
                    </a>
                    <img src={Logo} onClick={() => navigate('/')} alt="logo" />
                </header>

                {isLoading &&
                    <div className="loading">
                        <p className="title">
                            Aguarde...
                        </p>
                        <img className="logo-loading" src={IconLoading}></img>
                        <p className="desc">Os astros estão se alinhando para você...</p>
                    </div>}

                {!isLoading && <div className="fade-in"> {children}</div>}
            </div>

            <Footer />
        </>
    )
}

export default WithLoading;