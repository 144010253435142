import React, { useState, useEffect } from 'react'
import {
    Link, useNavigate
} from "react-router-dom";


import ReactGA from 'react-ga4';
import './index.scss'

import BackNext from '../../assets/Arrow-next.png';


import IconWay from '../../assets/iconWay.png';
import IconQuestion from '../../assets/IconQuestion.png';
import WithLoading from '../../components/WithLoading';

const arrayRocks = [
    {
        img: IconWay,
        title: 'Leitura de caminhos',
        desc: 'Descubra qual dos caminhos faz mais sentido para você',
        url: 'escolha-pedra',
        id: 0,
    },
    {
        img: IconQuestion,
        title: 'Pergunta específica',
        desc: 'Entenda mais sobre uma questão específica que você busca respostas',
        url: 'escolha-pedra',
        id: 1
    }
]

const ChooseRock = () => {

    return (WithLoading(<div>
        <section className="section-1">
            <p className="steps">como posso te ajudar?</p>
            <p className="title">Escolha o tipo de <br /> resposta que você está  <br /> buscando</p>
            <p className="desc" style={{ maxWidth: '300px' }}>A depender do que você escolher, a leitura e disposição de cartas será diferente.</p>

            <div className="list-rock">
                {arrayRocks.map(item => (
                    <Link className="box-rock" to={`/${item.url}?type=${item.id}`} onClick={() => {
                        ReactGA.event({
                            category: 'Escolher tipo',
                            action: item.title,
                            label: item.title
                        });
                    }}>
                        <img className="rock-img" src={item.img}></img>
                        <div className="col">
                            <p className="title-rock">{item.title}</p>
                            <p className="desc-rock">{item.desc}</p>
                        </div>
                        <img className="arrow-next" src={BackNext}></img>
                    </Link>
                ))}

            </div>
        </section>
    </div>));
}
export default ChooseRock;