// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
// Import Swiper styles


import ReactGA from 'react-ga4';

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";

import './index.scss'

import IconVerySad from '../../assets/Muito triste.png'
import IconBad from '../../assets/Meio triste.png'
import IconRegular from '../../assets/Regular.png'
import IconGood from '../../assets/Feliz 1.png'
import IconVeryGood from '../../assets/Muito feliz.png'


import { useLocation, useNavigate } from 'react-router-dom';

import IconQuartzoRosa from '../../assets/IconQuartzoRosa.png'
import IconCalcita from '../../assets/CalcitaVerde.png'
import IconCitrino from '../../assets/Citrino.png'
import IconSelenita from '../../assets/Selenita.png'
import WithLoading from '../../components/WithLoading';


const arrayRocks = [
    {
        img: IconQuartzoRosa,
        title: 'Quartzo rosa',
        desc: 'Pedra do amor',
        id: 0,
    },
    {
        img: IconSelenita,
        title: 'Selenita',
        desc: 'Pedra da conexão com guias espirituais e espiritualidade',
        id: 1
    },
    {
        img: IconCalcita,
        title: 'Calcita verde',
        desc: 'Pedra da paz espiritual e cura emocional',
        id: 2
    },
    {
        img: IconCitrino,
        title: 'Citrino',
        desc: 'Pedra da prosperidade e sucesso',
        id: 3
    }
]

const arrayCards = [
    {
        name: 'O Mago',
        tags: 'Poder pessoal, Força, Coragem',
        desc: 'O mago é aquele que pode criar tudo com as próprias mãos. \n\nQuantas vezes nos colocamos como vítimas de situações? E nos sentimos perdidos, com medo do que o mundo pode nos trazer, do que outras pessoas podem dizer, das mudanças que podem acontecer na vida. \n\nQuando na verdade, nós temos o poder de fazer tudo acontecer. O mago fala sobre poder pessoal: o que quer que você esteja almejando ou buscando, você tem total poder para conquistar.  \n\nProcure trabalhar gratidão e mentalizar a vida que você deseja. Trace planos e metas para conquistá-la, pois quando menos perceber, aquilo que você tanto deseja estará em suas mãos. \n\nEssa carta é muito positiva: você conseguirá o que você busca. Mas precisa acreditar em si mesmo.'
    },
    {
        name: 'A Sacerdotisa',
        tags: 'Introspecção, Autoreflexão, Autoconhecimento',
        desc: 'Uma carta tão feminina que parece ter saído do livro Mulheres que Correm com Lobos. A Sacerdotisa fala sobre a figura feminina sábia, intuitiva, que utiliza dos conhecimentos prévios e da sabedoria milenar para tomar as melhores decisões. \n\nÉ uma carta que pede introspecção. Não tenha medo de revirar seu passado em busca de respostas. É no passado que mora a sabedoria: desde conselhos de pessoas mais velhas sobre uma questão, ou desde você revisitar situações que passou para entender como elas te fizeram sentir. \n\nNem sempre é claro o que nos causa um mal-estar: muitas vezes a resposta está em situações passadas traumatizantes que trazem gatilhos de volta a tona, e até você conseguir racionalizar esses sentimentos, eles voltarão a te assombrar. \n\nMas você detém da intuição, o sexto sentido poderoso que nos norteia. Escute sua voz interior, ela vem tentando te dizer o que fazer. Você tem todo o poder de encontrar suas respostas, com fé, sabedoria, introspecção e autoconhecimento, tal qual a Sacerdotisa. Boa viagem. '
    },
    {
        name: 'A Imperatriz',
        tags: 'Poder pessoal, Criatividade, Beleza, Sabedoria',
        desc: 'Uma das cartas mais positivas do Tarot. Você já almejou ser aquela pessoa que é admirada por todas? Pois essa carta é o arquétipo que as pessoas desejam ser. \n\nA imperatriz é a mulher que foi coroada com beleza, sagacidade e poder. Ela é admirada e as pessoas olham com brilhos nos olhos quando vêem suas conquistas. \n\nÉ possível que você seja essa pessoa, admirada pelos outros - ou que tenha alguém na sua vida que esteja desempenhando esse papel. \n\nQualquer que tiver sido sua questão, lembre-se: a Imperatriz é capaz de resolver tudo com criatividade, sabedoria, e ela propaga a corrente de mudança no mundo. Você é capaz de resolver suas questões, pois você também foi coroado com essas qualidades. Confie no seu poder, pois mesmo sem notar, tem muitas pessoas que se inspiram em você. '
    },
    {
        name: 'O Imperador',
        tags: 'Controle, Domínio',
        desc: 'Ah, uma das cartas mais queridas pelos controladores... \n\nO Imperador fala sobre estar finalmente no domínio das suas próprias emoções, da sua vida, de tudo que tange você. \n\nÉ uma carta muito positiva, e fala sobre tranquilidade e domínio. Parece que o que quer que seja que essa questão esteja te trazendo, você tem tudo que é necessário para dar a volta por cima e sair vitorioso. \n\nLegal né? Mas lembre-se que para poder ter esse controle, ironicamente, é preciso desapegar-se de tentar controlar. Aquele que tudo domina, faz isso naturalmente - e aquele que nada domina, está sempre tentando carregar sozinho todos os pratos. Cuide-se e lembre que o universo é poderoso, e que o caminho feliz está bem ali na esquina. '
    },
    {
        name: 'O Sumo Sacerdote',
        tags: 'Espiritualidade, Conforto, Segurança, Autoridade',
        desc: 'O Papa pode significar muitas coisas. Dentre elas, uma necessidade de se reconectar com sua espiritualidade em busca de respostas, ou então buscar ajuda de algo ou alguém para conseguir entender que caminho deve seguir. \n\nVocê busca segurança e conforto, e essas são as palavras que norteiam O Papa. Esse conforto vem de muitas coisas: palavras divinas confortantes, informações que não sabíamos que podem nos ajudar na decisão, conversar com pessoas que já passaram por essa situação e podem lhe ajudar. O momento é de buscar ajuda. \n\nCaso sua pergunta seja sobre como resolver uma situação, ele alerta sobre diplomacia: o Papa é a figura mais diplomática do planeta, e sabe resolver questões de alta dificuldade com as palavras certas. Você precisa se munir de informações para tomar atitudes que lhe possam favorecer - assim sentirá o conforto e a segurança que tanto busca. \n\nE a reconexão com a espiritualidade não necessariamente precisa vir de uma religião. Ela pode vir de tirar alguns momentos para você se desligar do mundo e recarregar suas energias. Meditação, exercício, atividades prazeirosas, tudo isso lhe leva para outra dimensão espiritual, onde você leva a vida que tanto sonhou. Foque em você e em elevar seu espírito. '
    },
    {
        name: 'A Roda da Fortuna',
        tags: 'Mudanças, Transmutação, Novos começos',
        desc: 'Existe uma roda kármica que move o mundo: uma hora, a roda da fortuna gira e as coisas parecem se alinhar perfeitamente - E quando você menos espera, ela roda novamente, virando seu mundo de cabeça para baixo. \n\nQuer dizer que isso reflete algo que você fez? Não necessariamente. Assim é a vida: uma hora a ampulheta gira, até o tempo acabar e ela girar de novo. \n\nÉ preciso entender que, mesmo que as coisas mudem, muita coisa não está no seu controle. Porém algo sim está: a maneira que você lida com suas emoções. Aquele que tem domínio das suas emoções e bem-estar pode estar vivendo uma das épocas mais difíceis da humanidade, e ainda assim encontrar motivos para sorrir. \n\nMudanças vem por ai, podem ser boas, podem ser ruins. Mas o que vai definir seu futuro é: o que você fará com elas? Abraçará as mudanças com fogo nos olhos para viver novas experiências? Ou vai se fechar em amargura por aquilo que perdeu? \n\nAbra seus olhos: coisas boas estão por vir!  '
    },
    {
        name: 'A Estrela',
        tags: 'Esperança, Proteção, Otimismo',
        desc: 'Imagine que você caminha por muito tempo na escuridão, e encontra um feixe de luz. Como você se sentiria? Se sente esperançoso, e corre atrás da luz no fim do túnel? \n\nA estrela é uma carta que denota proteção espiritual. A pessoa abençoada pela estrela nunca perde a esperança: ela sabe que sua estrela vai brilhar novamente. \n\nE é com essa visão que você deve olhar a vida: com esperança por tempos melhores, pois é a esperança que nos move para longe da escuridão. \n\nVocê tem toda a proteção necessária para continuar sua jornada. E você tem dentro de si uma ânsia de viver a vida. Escute essa ânsia e acredite em você: deixe sua estrela brilhar mais forte.  '
    },
    {
        name: 'O Enamorado',
        tags: 'Livre-arbítrio, Decisões, Racionalidade',
        desc: 'Quem procura o Tarot, normalmente procura uma luz sobre um caminho ou uma decisão. Essa carta pode ser ainda mais confusa, pois ela aponta que só o livre-arbítrio pode levar ao melhor caminho. \n\nO que isso significa? Você tem opções na questão perguntada, e apenas você, mais ninguém, pode decidir qual o melhor caminho a se seguir. O livre-arbítrio remete a não deixar nada ou ninguém apontar o melhor caminho. \n\nMas como você pode tomar uma melhor decisão? Seja racional. Veja quais os pontos positivos e negativos de cada decisão. Quais os caminhos que ela pode te levar? Qual desses caminhos está mais alinhado com seus objetivos de vida? Quais caminhos você acredita que será mais feliz? \n\nObserve seu passado, observe atitudes e ações que lhe impulsionaram para a vida que você desejava, e quais lhe fizeram retroceder. Escute bem sua intuição, pois ela está tentando lhe dizer qual o caminho mais correto. \n\nMas não se preocupe: você tem proteção espiritual que rege essa carta. Qualquer caminho que você escolher, haverá uma forma de contornar.  '
    },
    {
        name: 'O Carro',
        tags: 'Caminho certo, Intuição, Positividade',
        desc: 'A carta do Carro fala sobre, resumidamente, a busca pelo caminho certo. \n\nIndependente do que você já tenha feito, você é uma pessoa que procura esse caminho ideal. Você sabe qual o caminho certo, é só ouvir mais tua intuição. Mas o certo e o errado dependem muito das nossas escolhas - para uns, o caminho certo é ser feliz. Para outros, é viver a vida intensamente. Qual o caminho certo para você? \n\nVale lembrar disso, quando refletir sobre sua pergunta. Pense: ”Eu sei qual é o caminho certo. E agora vou ouvir minha voz interior para tomar a melhor decisão para mim.”. Lembre-se: só você sabe o que é esse caminho certo. \n\nMas cuidado para não confundir intuição com paranóia.  '
    },
    {
        name: 'A Justiça',
        tags: 'Karma, Reflexão, Justiça',
        desc: 'A Justiça apela pela lei da causa e efeito. Tudo estará sendo regido pelo karma, então é necessário ter cuidado. Você está tomando as melhores decisões, ou está agindo por impulso? \n\nQuando A Justiça aparece, é possível que a situação esteja a seu favor, desde que você aja de forma justa. É preciso dicernir: o quanto eu sou a vítima, e quando eu estou ativamente agindo com más intenções. E por más intenções, não digo mau-caratismo, senão quando você toma decisões impulsionadas pelo seu ego. \n\nO ego tende a nos levar por um caminho que nem sempre é bonito, e nem sempre é o melhor. A necessidade de se proteger acaba causando autosabotagem, e pode te levar a agir de maneiras que não são as mais racionais - quer seja rejeitando alguém, falando algo que não queríamos falar, ou se fechando para o mundo e para as experiências da vida. \n\nPare, reflita e separe o que é seu ego e seu senso de justiça. Pois ainda que a justiça tarde, ela não falha. Uma hora ou outra, ela cobra.   '
    },
    {
        name: 'O Eremita',
        tags: 'Paciência, Prudência, Sabedoria',
        desc: 'Essa carta é tão necessária, mas ao mesmo tempo tão temida pelos ansiosos e impulsivos.\n\nExiste o momento de agir, e existe o momento de sentar, esperar e olhar as coisas com calma, e tomar a melhor decisão. \n\nVocê sabe bem que não se pode tomar decisões com desespero: elas podem lhe levar para um caminho mais desesperador do que você já está. Então o eremita pede prudência, calma, e muita sabedoria. \n\nNo ímpeto do desejo de mudança, muitas vezes não enxergamos que, se esperássemos mais alguns dias, algumas semanas, meses, as coisas poderiam ter se alinhado naturalmente, sem precisar de uma atitude sua. Mas a vontade de mudar é tão grande, que acabamos nos sabotando procurando a solução mais fácil. Não siga esse caminho: seja racional, faça uma lista de prós e contras, analise o que é melhor para você. \n\nÀs vezes, esperar é o melhor que você pode fazer por si mesmo. Leia essa frase de novo.  '
    },
    {
        name: 'A Força',
        tags: 'Forca, Determinação, Coragem',
        desc: 'Que difícil é controlar as vozes nas nossas cabeças! Elas muitas vezes nos botam pra cima, outras vezes nos dilaceram. Impulsividade, ansiedade, medos, angústias… Essas vozes vem e vão com alguma constância. \n\nMas essa carta é uma carta sobre força: você terá a força necessária para superar qualquer questão que vem surgindo e lhe afligindo. \n\nEla fala sobre domínio, sobre controle. Você tem tudo que é necessário para agir e estar no domínio de si. \n\nMas como você chega nisso? \n\nÉ preciso controlar aquilo que lhe controla. Mude as vozes da sua cabeça, faça sua mente se tornar um lugar confortável para se estar. Encha sua vida de positividade, e construa uma rotina saudável para equilibrar suas emoções. \n\nE lembre-se: você tem força para dominar o que quiser!.  '
    },
    {
        name: 'O Enforcado',
        tags: 'Romper amarras, Libertação',
        desc: 'Algo lhe faz sentir culpado sobre essa situação. Você sente que algum sacrifício precisa ser feito, e isso pesa dentro de você. \n\nA carta mostra um homem pendurado pelos pés, porém não parece disposto a remover com suas próprias mãos as amarras que lhe torcem o mundo. E mais: será que ele está pendurado? Ou será que o mundo que está de cabeça para baixo? \n\nExistem amarras que te prendem e te impedem de ver o mundo com outra perspectiva. Você tem duas escolhas: pode seguir mantendo as amarras e seguir vendo o mundo de cabeça para baixo; ou pode romper isso que te prende e ficar livre para um recomeço. \n\nEnquanto não nos permitimos ser livres, o mundo sempre estará ao avesso. E quanto mais coisas adversas acontecerem com você, mais você aceitará essa amarra. Mas o mundo sempre muda, e nem sempre tudo que a gente quer acontece. Mas como você vai lidar com essas situações e o quanto você vai lutar para manter o seu bem-estar, isso só depende de você. \n\nLiberte-se das amarras e perceba você, seus sentimentos e como isso te faz sentir. Quanto mais você tentar controlar uma situação, mais ela vai exercer controle sobre você. É preciso se desprender! Entregue ao universo e perceba como a liberdade é gratificante.   '
    },
    {
        name: 'A Morte',
        tags: 'Renovação, Mudança, Confiança ',
        desc: 'A Morte vem nos alertar sobre situações na nossa vida que não fazem sentido mais nas nossas vidas. Mas não se preocupe! A Morte é uma carta boa. Mas é preciso ter paciência para entender isso. \n\nAlgo em relação ao que você perguntou não parece lhe fazer sentido mais. É preciso da renovação - mas pra isso, também é preciso deixar morrer o que já não lhe serve. E isso não precisa ser um corte brusco - apenas uma realização do que precisa ser mudado e um conselho para que você mude. E tenha a confiança de saber que tudo vai dar certo - porque o que há de ser seu, o universo vai te trazer. \n\nComo diria Steve Jobs: ”Você não consegue ligar os pontos olhando pra frente. Você só consegue ligá-los olhando para trás. Então você precisa confiar que os pontos vão de alguma maneira se conectar no seu futuro. Você tem que confiar em algo”. Entrega para o universo!  '
    },
    {
        name: 'A Temperança',
        tags: 'Paciência, Equilíbrio, Autoconhecimento',
        desc: 'A paciência é a mensagem dessa carta. Você precisa procurar o equilíbrio e a paz para conseguir tomar melhores decisões. E a única forma de você fazer isso é entendendo quem você é, se conhecendo mais e entendendo seu propósito. \n\nO autoconhecimento é a chave: porque eu me sinto dessa forma? Porque tal coisa me faz sofrer? Eu não entendo! \n\nAgora é o momento de entender. Se algo lhe aflige ou lhe faz sofrer, é o momento de você olhar para trás, para o passado, e buscar nesses momentos a resposta para suas dores do presente. \n\nO passado é a chave para entender seu futuro. De alguma forma, você precisa chegar numa reflexão filosófica sobre quem você é, o que você gosta, o que você se propõe a ser. E tudo aquilo que não lhe fez bem, por qual razão não lhe fez bem? Detalhe esse sentimento num papel. Tente deixar sua intuição e seus sentimentos falarem mais alto. \n\nE tenha paciência: essa jornada é longa, mas é o segredo para uma vida feliz. '
    },
    {
        name: 'O Diabo',
        tags: 'Vício, Controle, Determinação',
        desc: 'Ah, o diabo… você pode notar que na carta, temos um casal acorrentado ao diabo. O que ele significa? \n\nImagine a época mais feliz da sua vida. E imagine que logo depois, você teve uma grande mudança, que lhe fez perder aquilo que tinha conquistado. E agora você perambula pelo mundo procurando aquilo que lhe dava propósito - e nesse caminho, se perde em bengalas emocionais para seguir. \n\nIsso é o diabo: aquilo que nos controla, mas que só está lá para desviar nossa atenção daquilo que realmente buscamos, da real questão que nos motiva. \n\nÉ muito comum que nos percamos em bengalas (vícios) para desviar nossa atenção do que realmente nos aflige. Seja comida, seja alguém, seja uma ideia, uma droga. Mas não é isso que vai lhe dar propósito ou que lhe alimenta. \n\nO diabo, todavia, não é uma carta ruim. Ele fala que é preciso determinação para sair dessa - deixar para lá aquilo que é um vício, e focar em buscar o seu propósito de ser. Ele é um prenúncio da libertação. Seja forte e siga em frente - e não deixe o diabo controlar você!  '
    },
    {
        name: 'A Torre',
        tags: 'Mudança, Ruptura, Renovação',
        desc: 'Já ouviu a história da Torre de Babel? É isso que inspira a carta da Torre. A Torre é tudo aquilo que, de fora, parece forte como concreto - até que, do nada, parece ruir, e desabamos do alto dela. \n\nUma grande mudança aconteceu, ou acontecerá, e você tem medo disso. Tem medo de descer do alto da torre que você construiu com tanto afinco. \n\nMas não se preocupe: a Torre que desaba nos mostra que na verdade, ela nunca teve sustento suficiente para se manter. Ela é uma mudança necessária do destino para trazer lucidez a quem a tira. \n\nEla remete ao caos, mas o caos também é aprendizado: será que eu realmente era feliz nessa torre? Será que algo que realmente era tão sólido teria ruído tão facilmente? \n\nPassamos por Torres nossa vida toda, e é o que levamos dela que nos dá estrutura para construir outros castelos - e esses cada vez mais resistentes. Você sairá dessa situação de cabeça erguida e com o otimismo de que aquilo que desabou, não era para ser seu, e que agora você está cada vez mais próximo da sua verdadeira felicidade. '
    },
    {
        name: 'A Lua',
        tags: 'Ilusões, Lucidez, Oculto',
        desc: 'A Lua representa o sagrado feminino, o místico, o oculto. Ela é um dos símbolos de beleza, de atração. Ao mesmo tempo, no Tarot, ela fala bastante sobre ilusões. \n\nNem tudo que reluz é ouro, já diria o ditado. É possível que tenha algo que você deseja intensamente, mas que não seja bem isso que você imaginava. \n\nA maneira de escapar do brilho da lua e não se perder é: lucidez. É preciso saber onde você está pisando. E você pode fazer isso estudando mais sobre a questão, conversando com outras pessoas, amigos, colegas, pedindo segundas opiniões. Enxergue a realidade como ela é. \n\nA realidade é dura, e não é tão fantasiosa como acontece na sua mente. E é engraçado, pois na sua mente, tudo sempre tem um final feliz. Mas felizmente, a realidade é muito melhor do que o sonho - somente sabendo a verdade é que poderemos nos libertar e viver experiências tais quais elas realmente são. \n\nE cuidado quando olhar para a lua, se não você se perde no seu brilho, e acaba se afogando no mar! '
    },
    {
        name: 'O Sol',
        tags: 'Felicidade, Bons presságios, Otimismo',
        desc: 'Se é felicidade e otimismo que você busca, tenho boas notícias: o Sol é a carta da felicidade mais genuína. \n\nVocê tem uma luz pessoal, que ainda que não consiga enxergar, consegue trazer felicidade e sorrisos nas pessoas ao seu redor. Essa luz que você tem também te proteje nos seus projetos e no que você busca para a vida. \n\nEssa carta é o prenúncio de bons momentos, experiências e momentos muito felizes. Existe muita proteção espiritual nessa carta, então pode ficar sossegado: vem muita coisa boa por ai. \n\nBusque o caminho da felicidade sempre, com muito otimismo e gratidão. Você tem um poder muito grande, e ilumina caminhos por onde passa. '
    },
    {
        name: 'O Julgamento',
        tags: 'Karma, Perdão, Segundas chances',
        desc: 'É chegada a hora de fazer um acerto de contas com o passado. O Julgamento é uma carta que fala sobre karma e como decisões passadas podem afetar seu futuro. \n\nQuando falo essa frase em consultas de Tarot, as pessoas costumam se encolher, com medo do que pode vir. É normal pensarmos: será que o que eu fiz foi o certo? Será que agora vou colher coisas ruins, pois não plantei coisas tão legais? \n\nO passado já passou, mas toda ação gera uma reação. E a única forma de você impedir uma reação negativa, é tomando consciência dos seus erros e acertos passados para saber o que deve seguir fazendo e o que deve abolir. \n\nO que passou, deixe para trás. Encontre o perdão em si mesmo e perdoe quem te ofendeu. Guardar rancor para si é beber um copo de veneno e esperar que o outro morra. \n\nE se você foi o ofensor, encontre maneiras de buscar o perdão: seja fazendo o bem para a comunidade, seja conversando com quem você ofendeu, seja buscando a paz ao seu redor. Não carregue sentimentos negativos com você. \n\nQualquer que seja o seu passado, reflita e ponha os pingos nos is. Pois mudanças virão e é bom você estar armado de sabedoria - e sabendo o que pode fazer de melhor na próxima vez. '
    },
    {
        name: 'O Mundo',
        tags: 'Experiências, Oportunidades, Otimismo',
        desc: 'A carta mais amada do Tarot. O Mundo é, literalmente, um mundo. Um mundo de oportunidades, experiências, pessoas, projetos que surgirão na sua vida. \n\nÉ o prenúncio de uma fase com muita movimentação para você, especialmente movimentações positivas! É chegado o momento de desengavetar aquela ideia, aquele plano, aquela viagem, aquele sonho. O mundo se abre para você e é o momento de fazer tudo acontecer! \n\nViva essa fase da melhor maneira: se entregue as experiências por inteiro. Viva o que tiver de ser vivido, ame quem tiver de ser amado, se jogue pois o maior arrependimento será daquilo que você não viveu. \n\nJá diria a canção: “Só uma coisa me entristece, o beijo de amor que não roubei, a jura secreta que não fiz..”. Viva intensamente!'
    },
    {
        name: 'O Louco',
        tags: 'Otimismo, Descoberta, Novidades',
        desc: 'Essa carta fala sobre novos começos, recomeços, novas experiências, ou seja: o novo. \n\nO novo bate na sua porta, e como você vai reagir? \n\nVocê pode se fechar, e se amargurar por um novo começo. Pode até ter medo. \n\nOu você pode ter o olhar de uma criança. E como é o olhar da criança? Ela é inguênua, ela se admira com o sol, com o mar, com as estrelas. Tudo é novo para ela, porém ao mesmo tempo, tudo é empolgante, é uma descoberta. \n\nAssim deve ser você. Enxergue esse começo como o prenúncio de uma vida nova, cheia de oportunidades para você. Acredite que o que virá, poderá te causar tantos sentimentos positivos. Pessoas que você pode conhecer, amigos que fará, caminhos que se abrirão (quem sabe um novo amor ou um novo projeto não podem surgir?? \n\nSeja otimista! Pois um novo começo é cheio de momentos de criança: primeiras vezes.  '
    }
];

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const ShowResult = () => {

    const refScroll = useRef(null);

    const scrollTo = () => {
        refScroll.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const navigate = useNavigate();

    const [result, setResult] = useState([])

    const [qtdReaded, setQtdReaded] = useState(0)

    const [showFeeback, setShowFeeback] = useState(false)

    const [showFeebackRegular, setShowFeebackRegular] = useState(false)

    const [showFeebackRBad, setShowFeebackBad] = useState(false)


    const [feedbackType, setFeedbackType] = useState(0)

    const [feedbackFeel, setFeedbackFeel] = useState([])

    const [arrayCardsSelectedFromWay, setArrayCardsSelectedFromWay] = useState([])

    const [currentCard, setCurrentCard] = useState(0)

    const [isOnSecondWay, setIsOnSecondWay] = useState(false)

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery();

    const getCards = () => {
        const cards = (query.get("cards").split(',')).slice(0, 3)

        cards.map(item => {
            arrayCards.map(card => {
                if (card.name.toUpperCase().replace('Ç', 'C').replaceAll(`-`, ` `) === item.toUpperCase().replace('Ç', 'C').replaceAll(`-`, ` `)) {
                    card.name = card.name.toUpperCase().replaceAll(`-`, ` `)
                    setResult(prev => [...prev, card])
                }
            })
        })

        const cardsFromWay = (query.get("cards").split(',')).slice(3, 6)

        cardsFromWay.map(item => {
            arrayCards.map(card => {
                if (card.name.toUpperCase().replace('Ç', 'C').replaceAll(`-`, ` `) === item.toUpperCase().replace('Ç', 'C').replaceAll(`-`, ` `)) {
                    card.name = card.name.toUpperCase().replaceAll(`-`, ` `)
                    setArrayCardsSelectedFromWay(prev => [...prev, card])
                }
            })
        })

    }

    const share = async () => {
        const shareData = {
            title: 'Resultado do meu Tarot',
            text: 'Vê que legal o resultado do meu Tarot do Autoconhecimento :)',
            url: window.location.href
        }

        try {
            await navigator.share(shareData)
            alert('compartilhado com sucesso!')
        } catch (err) {
            navigator.clipboard.writeText(window.location.href).then(() => {
                // Alert the user that the action took place.
                // Nobody likes hidden stuff being done under the hood!
                alert(`Link copiado com sucesso!`);
            });

        }


    }

    const resetFeedbacks = () => {
        setFeedbackFeel([])

    }
    const filterFeedbackFeel = (item) => {

        if (feedbackFeel.find(key => item == key)) {
            setFeedbackFeel(feedbackFeel.filter((key) => item !== key))
        } else {
            setFeedbackFeel([...feedbackFeel, item])
        }
    }

    const onSlideChange = (item) => {
        setCurrentCard(item.activeIndex)
        setQtdReaded(prev => prev + 1)
    }

    useEffect(() => {
        getCards();
        setTimeout(() => { scrollTo() }, 300)

    }, [])

    return (WithLoading(<section ref={refScroll} className="section-1">
        <p className="steps">Passo 3 de 3</p>
        <p className="title">Interpretação</p>
        <p className="desc">O que o seu subconsciente tem a <br /> dizer sobre essa questão?</p>
        {query.get('type') == 0 &&
            <div className="tab">
                <a href="javascript:void(0);" className={`tablinks tab1 ${!isOnSecondWay ? ' selected-tab' : ''}`} onClick={() => setIsOnSecondWay(prev => !prev)} >Primeiro caminho</a>
                <a href="javascript:void(0);" className={`tablinks tab2 ${isOnSecondWay ? ' selected-tab' : ''}`} onClick={() => setIsOnSecondWay(prev => !prev)} >Segundo caminho</a>
            </div>
        }
        {result.length >= 2 && <div className="result">
            {query.get('type') == 0 && !isOnSecondWay && <p className="desc-step">"{query.get('q1')}"</p>}
            {query.get('type') == 0 && isOnSecondWay && <p className="desc-step">"{query.get('q2')}"</p>}
            <Swiper
                navigation
                pagination={{ clickable: true }}
                effect="coverflow"
                coverflowEffect={{
                    rotate: 0,
                    stretch: 10,
                    depth: 160,
                    modifier: 3,
                    slideShadows: false
                }}
                slideToClickedSlide={true}
                slidesPerView={2}
                centeredSlides
                centeredSlides
                onSlideChange={onSlideChange}
            >

                {query.get('type') == 1 && result.map(item => (
                    <SwiperSlide>
                        <img className="card" src={require(`../../assets/${item.name.toUpperCase().replace('Ç', 'C')}.png`)} />
                    </SwiperSlide>
                ))}

                {query.get('type') == 0 && !isOnSecondWay && result.map(item => (
                    <SwiperSlide>
                        <img className="card" src={require(`../../assets/${item.name.toUpperCase().replace('Ç', 'C')}.png`)} />
                    </SwiperSlide>
                ))}

                {query.get('type') == 0 && isOnSecondWay && arrayCardsSelectedFromWay.map(item => (
                    <SwiperSlide>
                        <img className="card" src={require(`../../assets/${item.name.toUpperCase().replace('Ç', 'C')}.png`)} />
                    </SwiperSlide>
                ))}

            </Swiper>
            <div className="content-card">
                <p className={`tag ${currentCard === 0 ? ' primeira' : currentCard === 1 ? ' segunda' : ' terceira'}`}>{currentCard === 0 ? 'Primeira' : currentCard === 1 ? 'Segunda' : 'Terceira'} carta</p>

                {query.get('type') == 1 && <> <h3 className="title-card">{result[currentCard].name}</h3>
                    <p className="desc-card">{result[currentCard].desc}</p>
                    <div className="card-tags">
                        {result[currentCard].tags.split(',').map(item => <div className="tag-content">{item}</div>)}
                    </div>
                </>}

                {query.get('type') == 0 && !isOnSecondWay && <> <h3 className="title-card">{result[currentCard].name}</h3>
                    <p className="desc-card">{result[currentCard].desc}</p>
                    <div className="card-tags">
                        {result[currentCard].tags.split(',').map(item => <div className="tag-content">{item}</div>)}
                    </div>
                </>}
                {query.get('type') == 0 && isOnSecondWay && <>
                    <h3 className="title-card">{arrayCardsSelectedFromWay[currentCard].name}</h3>
                    <p className="desc-card">{arrayCardsSelectedFromWay[currentCard].desc}</p>
                    <div className="card-tags">
                        {arrayCardsSelectedFromWay[currentCard].tags.split(',').map(item => <div className="tag-content">{item}</div>)}
                    </div>
                </>}
                <a className="box-rock green">
                    <img className="rock-img" src={arrayRocks[query.get("rock") || query.get("pedra")].img}></img>
                    <div className="col">
                        <p className="title-rock">{arrayRocks[query.get("rock") || query.get("pedra")].title}</p>
                        <p className="desc-rock">{arrayRocks[query.get("rock") || query.get("pedra")].desc}</p>
                    </div>
                </a>

                {qtdReaded >= 2 && <div className="finish">
                    <h3 className="finish-title">Você terminou sua leitura!</h3>
                    <p className="finish-desc">Que bom que chegou até aqui! Como você avaliaria esta sessão, de acordo com a questão que você perguntou?</p>
                    <div className="feedback">
                        <div className={`item ${feedbackType == 1 ? 'selected' : ''}`} onClick={() => {
                            setShowFeebackBad(true); setShowFeeback(false); setShowFeebackRegular(false); setFeedbackType(1); resetFeedbacks();
                            ReactGA.event({
                                category: 'Resultado',
                                action: 'feedback',
                                label: 'Muito ruim'
                            });
                        }}>
                            <img src={IconVerySad} alt="" title="feedback-very-sad" />
                            <p>Muito ruim</p>
                        </div>
                        <div className={`item ${feedbackType == 2 ? 'selected' : ''}`} onClick={() => {
                            setShowFeebackBad(true); setShowFeeback(false); setShowFeebackRegular(false); setFeedbackType(2); resetFeedbacks();
                            ReactGA.event({
                                category: 'Resultado',
                                action: 'feedback',
                                label: 'ruim'
                            });
                        }}>
                            <img src={IconBad} alt="" title="feedback-sad" />
                            <p>ruim</p>
                        </div>
                        <div className={`item ${feedbackType == 3 ? 'selected' : ''}`} onClick={() => {
                            setShowFeebackRegular(true); setShowFeeback(false); setShowFeebackBad(false); setFeedbackType(3); resetFeedbacks();
                            ReactGA.event({
                                category: 'Resultado',
                                action: 'feedback',
                                label: 'regular'
                            });
                        }}>
                            <img src={IconRegular} alt="" />
                            <p>regular</p>
                        </div>
                        <div className={`item ${feedbackType == 4 ? 'selected' : ''}`} onClick={() => {
                            setShowFeeback(true); setShowFeebackRegular(false); setShowFeebackBad(false); setFeedbackType(4); resetFeedbacks();
                            ReactGA.event({
                                category: 'Resultado',
                                action: 'feedback',
                                label: 'boa'
                            });
                        }}>
                            <img src={IconGood} alt="" title="feedback-good" />
                            <p>boa</p>
                        </div>
                        <div className={`item ${feedbackType == 5 ? 'selected' : ''}`} onClick={() => {
                            setShowFeeback(true); setShowFeebackRegular(false); setShowFeebackBad(false); setFeedbackType(5); resetFeedbacks();
                            ReactGA.event({
                                category: 'Resultado',
                                action: 'feedback',
                                label: 'muito boa'
                            });
                        }}>
                            <img src={IconVeryGood} alt="" title="feedback-very-sad" />
                            <p>muito boa</p>
                        </div>
                    </div>
                    {showFeeback &&
                        <div className="how-feel">
                            <p className="how-feel-text">Que massa que você gostou! O que você sente agora, após a leitura?</p>
                            <div className="card-tags">
                                <div className={`tag-content ${feedbackFeel.includes(1) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(1);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Bem-estar'
                                    });
                                }}>Bem-estar</div>
                                <div className={`tag-content ${feedbackFeel.includes(2) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(2);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Positividade'
                                    });
                                }}>Positividade</div>
                                <div className={`tag-content ${feedbackFeel.includes(3) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(3);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Conexão com espiritualidade'
                                    });
                                }}>Conexão com espiritualidade</div>
                                <div className={`tag-content ${feedbackFeel.includes(4) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(4);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Tranquilidade'
                                    });
                                }}>Tranquilidade</div>
                                <div className={`tag-content ${feedbackFeel.includes(5) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(5);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Paz'
                                    });
                                }}>Paz</div>
                            </div>
                        </div>
                    }

                    {showFeebackRegular &&
                        <div className="how-feel">
                            <p className="how-feel-text">Poxa, parece que não foi tão legal assim. O que você não gostou na experiência?</p>
                            <div className="card-tags">
                                <div className={`tag-content ${feedbackFeel.includes(1) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(1);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Não fez sentido para o que perguntei'
                                    });
                                }}>Não fez sentido para o que perguntei</div>
                                <div className={`tag-content ${feedbackFeel.includes(2) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(2);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Pouca conexão'
                                    });
                                }}>Pouca conexão</div>
                                <div className={`tag-content ${feedbackFeel.includes(3) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(3);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Gerou ansiedade'
                                    });
                                }}>Gerou ansiedade</div>
                                <div className={`tag-content ${feedbackFeel.includes(4) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(4);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Não senti firmeza'
                                    });
                                }}>Não senti firmeza</div>
                            </div>
                        </div>
                    }

                    {showFeebackRBad &&
                        <div className="how-feel">
                            <p className="how-feel-text">Que pena! O que você não curtiu nas previsões?</p>
                            <div className="card-tags">
                                <div className={`tag-content ${feedbackFeel.includes(1) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(1);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Não fez sentido para o que perguntei'
                                    });
                                }}>Não fez sentido para o que perguntei</div>
                                <div className={`tag-content ${feedbackFeel.includes(2) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(2);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Pouca conexão'
                                    });
                                }}>Pouca conexão</div>
                                <div className={`tag-content ${feedbackFeel.includes(3) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(3);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Gerou ansiedade'
                                    });
                                }}>Gerou ansiedade</div>
                                <div className={`tag-content ${feedbackFeel.includes(4) ? 'feedback-selected' : ''}`} onClick={() => {
                                    filterFeedbackFeel(4);
                                    ReactGA.event({
                                        category: 'feedback',
                                        action: 'how-feel',
                                        label: 'Não senti firmeza'
                                    });
                                }}>Não senti firmeza</div>
                            </div>
                        </div>
                    }
                    <div className="receive-email">
                        <p className="receive-email-text">Receba sua leitura para ler depois ou compartilhe com seus amigos!</p>

                        <a href="javascript:void(0);" onClick={() => { share() }} className="btn-share">Compartilhar minha leitura</a>
                    </div>
                </div>
                }
            </div>
        </div>}
    </section>));
}
export default ShowResult;