import React from 'react'
import ReactGA from 'react-ga4';
import './index.scss'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer';

const Home = () => {
    return (
        <main className="home">
            <div className="page-section section-1" style={{ backgroundImage: `url(${require('../../assets/bg-sky.png')})` }}>
                <div className="container">
                    <img src={require(`../../assets/Logo.png`)} alt="" className="header-logo" />
                    <h1 className="main-title">
                        Esse era o sinal que <br /> você procurava.
                    </h1>
                    <p className="main-paragraph max-w">
                        Tire o Tarot do Autoconhecimento e surpreenda-se com o que seu subconsciente tem a lhe dizer.
                    </p>
                    <Link to="escolha-tipo" onClick={() => {
                        ReactGA.event({
                            category: 'Home',
                            action: 'Embaralhar as cartas',
                            label: 'Embaralhar as cartas'
                        });
                    }} className="home-button">
                        Embaralhar as cartas
                    </Link>

                    <p style={{ textAlign: 'center', lineHeight: '22px', minWidth: '300px', marginLeft: '26px' }}>
                        <strong style={{ position: 'relative' }}><img src={require(`../../assets/Visible.png`)} style={{ position: 'absolute', left: '-26px' }} alt=""></img> + de 100 pessoas</strong> já utilizaram o <br /> Tarot do Autoconheccimento
                    </p>

                </div>
            </div>
            <div className="page-section section-2 container-desk">
                <div className="moon-group">
                    <img src={require('../../assets/left-moon.png')} alt="" />

                    <img src={require('../../assets/full-moon.png')} alt="" />

                    <img src={require('../../assets/right-moon.png')} alt="" />
                </div>


                <div className="container-section-2">
                    <h5 className="profile-title">
                        Quem faz as interpretações?
                    </h5>
                    <div className="image-group-section-2">
                        <img src={require('../../assets/profile-photo.png')} className="profile-picture" alt="" />
                        <img src={require('../../assets/logo-yellow.png')} alt="" className="logo-yellow" />
                    </div>

                    <span className="profile-name" style={{ marginBottom: '15px' }}>Teresa Castro</span>
                    <p className="about-text max-w">
                        Cartomante há 6 anos, faz leituras ao vivo e online. É Designer de Produto, Especialista em Design de
                        Interação e mística nas horas vagas.
                    </p>
                    <a href="mailto:taarotdoautoconhecimento@gmail.com" className="contact-link" onClick={() => {
                        ReactGA.event({
                            category: 'Home',
                            action: 'Entrar em contato',
                            label: 'Entrar em contato'
                        });
                    }} >Entre em contato
                        <img src={require('../../assets/ArrowRight-orange.png')}></img>
                    </a>
                </div>

            </div>
            <div className="page-section section-3 container-desk">
                <div className="header-section-3">
                    <h3 className="title-section-3">
                        Números de impacto
                    </h3>
                    <img src={require('../../assets/Saturno.png')} alt="" className="saturn-picture" />
                </div>
                <div className="container-scroll">
                    <div className="card-numbers  mt-1">
                        <h3 className="card-score">
                            Nota 9,0
                        </h3>
                        <p className="card-emphasis">em precisão nos resultados</p>
                        <p className="card-description">Esta foi a média de avalliações dos nossos consulentes após terminarem
                            uma consulta</p>
                    </div>
                    <div className="card-numbers  mt-2">
                        <h3 className="card-score">
                            10 em cada 10
                        </h3>
                        <p className="card-emphasis">pessoas recomendam este Tarot</p>
                        <p className="card-description">A experiência de se consultar mostrou-se positiva para os consulentes
                        </p>
                    </div>
                    <div className="card-numbers  mt-3">
                        <h3 className="card-score">
                            99%
                        </h3>
                        <p className="card-emphasis">acredita que a experiência lhe proporcionou tranquilidade e segurança</p>
                        <p className="card-description">O Tarot é uma ferramenta de autocuidado, acima de tudo</p>
                    </div>
                    <div className="card-numbers  mt-4">
                        <h3 className="card-score">
                            90%
                        </h3>
                        <p className="card-emphasis">se sentiram mais positivos e confiantes após a consulta</p>
                        <p className="card-description">Nossos consulentes demonstraram positividade após os resultados do Tarot
                        </p>
                    </div>
                </div>

            </div>
            <div className="page-section section-4 container-desk">
                <h3><strong> Quem tirou, o que achou </strong></h3>
                <p>Depoimentos de consulentes que<br /> tiraram o Tarot do Autoconhecimento</p>

                <div className="testimonials-scroll">
                    <div className="card-testimonial testimonial-sophia">
                        <p className="testimonial-content">
                            “Pra quem achava que tecnologia não se conecta com o étereo, o Tarot do Autoconhecimento foi uma surpresa incrível. A experiência foi muito fluida e individualizada, e a explicação de cada carta deu um ar de credibilidade, além de me proporcionar conhecer mais sobre o tarot para além da leitura.”
                        </p>
                        <div className="container-author">
                            <img src={require('../../assets/Lua.png')} style={{ width: '75px' }} alt="" className="testimonial-picture" />
                            <div className="container-signature">
                                <p className="testimonial-author">Lua</p>
                                <p className="testimonial-date">Consultou em abril<br /> de 2022</p>
                            </div>
                        </div>

                    </div>
                    <div className="card-testimonial testimonial-marcia">
                        <p className="testimonial-content">
                            “O Tarot sempre foi para mim uma ferramenta de conexão com meus guias espirituais. O Tarot do autoconhecimento facilita o encontro consigo mesmo, e eu amei a experiência! Me sinto mais forte pra tomar decisões!”
                        </p>
                        <div className="container-author">
                            <img src={require('../../assets/Le.png')} style={{ width: '75px' }} alt="" className="testimonial-picture" />
                            <div className="container-signature">
                                <p className="testimonial-author">Le</p>
                                <p className="testimonial-date">Consultou em Junho<br /> de 2022</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-testimonial testimonial-sophia">
                        <p className="testimonial-content">
                            “Quando estava tirando o Tarot do autoconhecimento me senti inicialmente apreensivo pra saber o que as cartas tinham pra me dizer, mas muito confortável em entender que as indicações passadas eram muito mais um retrato dos meus sentimentos do que uma resposta concreta. Gostei muito de escolher uma pedra antes, isso fez que eu sentisse uma certa identificação e personalização da experiência. Tiraria mais vezes para poder ter cada vez mais autoconhecimento e exercitar minha espiritualidade.”
                        </p>
                        <div className="container-author">
                            <img src={require('../../assets/eder.png')} style={{ width: '75px' }} alt="" className="testimonial-picture" />
                            <div className="container-signature">
                                <p className="testimonial-author">Eder</p>
                                <p className="testimonial-date">Se consultou em maio<br /> de 2022</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="page-section section-5 container-desk">
                <p className="how-it-works">Como funciona</p>
                <h3>Leituras colaborativas</h3>
                <div className="steps-container">
                    <div className="step-container">
                        <div className="step-profile">
                            <p className="step-number">1</p>
                            <img src={require('../../assets/ellipse-steps.png')} alt="" />
                        </div>
                        <p className="step-title">Mentalize um problema</p>
                        <p className="step-content">Pense numa questão que você gostaria de ajuda do Tarot para refletir ou
                            resolver</p>
                    </div>
                    <div className="step-container">
                        <div className="step-profile">
                            <p className="step-number">2</p>
                            <img src={require('../../assets/ellipse-steps.png')} alt="" />
                        </div>
                        <p className="step-title">Espiritualize-se</p>
                        <p className="step-content">Selecione uma das pedras disponíveis e escolha 3 cartas, mentalizando seu
                            problema e pedindo conselhos aos seus guias espirituais</p>
                    </div>
                    <div className="step-container">
                        <div className="step-profile">
                            <p className="step-number">3</p>
                            <img src={require('../../assets/ellipse-steps.png')} alt="" />
                        </div>
                        <p className="step-title">Receba o conselho</p>
                        <p className="step-content">Leia o conselho, reflita se faz sentido e avalie a consulta (queremos
                            melhorar cada vez mais nosso serviço!)</p>
                    </div>
                </div>
            </div>
            <div className="page-section section-6 container-desk">
                <div className="yellow-container">
                    <img className="star-1" src={require('../../assets/Star 1.png')}></img>
                    <img className="star-2" src={require('../../assets/Star 3.png')}></img>
                    <img></img>
                    <h3>  O que é o Tarot do Autoconhecimento? </h3>
                    <p>
                        <br />
                        A chave para o seu futuro está em ouvir seu subconsciente e sua intuição. Já ouviu a frase: ”A
                        resposta
                        sempre esteve você”? <br /><br />

                        Nas profundezas da nossa mente, temos as respostas pras nossas questões: quer seja o que faremos do
                        futuro ou qual a melhor decisão a se tomar.<br /><br />
                        O Tarot do Autoconhecimento é uma ferramenta para mediar essa reflexão interna e trazer a luz qual
                        decisão ou caminho faz mais sentido para você.
                    </p>
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default Home;