// @ts-ignore
import React from 'react'
import {
    Link, useLocation, useNavigate
} from "react-router-dom";

import './index.scss'
import ReactGA from 'react-ga4';

import BackNext from '../../assets/Arrow-next.png'
import IconQuartzoRosa from '../../assets/IconQuartzoRosa.png'
import IconCalcita from '../../assets/CalcitaVerde.png'
import IconCitrino from '../../assets/Citrino.png'
import IconSelenita from '../../assets/Selenita.png'
import WithLoading from '../../components/WithLoading';
import Footer from '../../components/Footer';




const arrayRocks = [
    {
        img: IconQuartzoRosa,
        title: 'Quartzo rosa',
        desc: 'Pedra do amor',
        id: 0,
    },
    {
        img: IconSelenita,
        title: 'Selenita',
        desc: 'Pedra da conexão com guias espirituais e espiritualidade',
        id: 1
    },
    {
        img: IconCalcita,
        title: 'Calcita verde',
        desc: 'Pedra da paz espiritual e cura emocional',
        id: 2
    },
    {
        img: IconCitrino,
        title: 'Citrino',
        desc: 'Pedra da prosperidade e sucesso',
        id: 3
    }
]

const ChooseRock = () => {

    const navigate = useNavigate();

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery();

    return (
        WithLoading(
            <div className="fade-in">
                <section className="section-1">
                    <p className="steps">Passo 1 de 3</p>
                    <p className="title">Escolha uma pedra <br /> do poder</p>
                    <p className="desc">Ela vai ajudar a alinhar sua energia <br /> com a das cartas. Escolha a que te <br /> chamar mais.</p>

                    <div className="list-rock">

                        {arrayRocks.map(item => (
                            <Link className="box-rock" title={`${item.title}`} to={`${query.get('type') == '1' ? '/escolha-carta' : '/definir-caminhos'}?type=${query.get('type')}&rock=${item.id}`}
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'Escolher pedra',
                                        action: item.title,
                                        label: item.title
                                    });
                                }}
                            >
                                <img className="rock-img" src={item.img}></img>
                                <div className="col">
                                    <p className="title-rock">{item.title}</p>
                                    <p className="desc-rock">{item.desc}</p>
                                </div>
                                <img className="arrow-next" src={BackNext}></img>
                            </Link>
                        ))}
                    </div>
                </section>
            </div>)

    );
}
export default ChooseRock;