
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ReactGA from 'react-ga4';

import './index.scss'

import Logo from '../../assets/logo-final.png'
import BackButton from '../../assets/back-btn.png'
import BackNext from '../../assets/Arrow-next.png'

import IconQuartzoRosa from '../../assets/IconQuartzoRosa.png'
import IconCalcita from '../../assets/CalcitaVerde.png'
import IconCitrino from '../../assets/Citrino.png'
import IconSelenita from '../../assets/Selenita.png'

import CardImg from '../../assets/card-back.png'
import Footer from '../../components/Footer';



const arrayRocks = [
    {
        img: IconQuartzoRosa,
        title: 'Quartzo rosa',
        desc: 'Pedra do amor',
        id: 0,
    },
    {
        img: IconSelenita,
        title: 'Selenita',
        desc: 'Pedra da conexão com guias espirituais e espiritualidade',
        id: 1
    },
    {
        img: IconCalcita,
        title: 'Calcita verde',
        desc: 'Pedra da paz espiritual e cura emocional',
        id: 2
    },
    {
        img: IconCitrino,
        title: 'Citrino',
        desc: 'Pedra da prosperidade e sucesso',
        id: 3
    }
]

const shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}



let arrayAllCards = [
    'O JULGAMENTO',
    'A LUA',
    'A MORTE',
    'A JUSTICA',
    'O DIABO',
    'A IMPERATRIZ',
    'O IMPERADOR',
    'O MAGO',
    'A TEMPERANCA',
    'O ENAMORADO',
    'O ENFORCADO',
    'A TORRE',
    'A ESTRELA',
    'A RODA DA FORTUNA',
    'O SOL',
    'O LOUCO',
    'O EREMITA',
    'A FORCA',
    'O MUNDO',
    'O SUMO SACERDOTE',
    'O CARRO',
    'A SACERDOTISA'
]

shuffleArray(arrayAllCards)

const ChooseCard = () => {


    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    const refScroll = useRef(null);

    const scrollTo = () => {
        refScroll.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const navigate = useNavigate();

    const [shakeCards, setShakeCards] = useState(true);

    const [animationClassFirstWay, setAnimationClassFirstWay] = useState(false);

    const [animationClassSecondWay, setAnimationClassSecondtWay] = useState(false);

    const [arrayCard1, setArrayCard1] = useState(arrayAllCards.slice(0, 6));

    const [arrayCard2, setArrayCard2] = useState(arrayAllCards.slice(6, 12));

    const [arrayCard3, setArrayCard3] = useState(arrayAllCards.slice(12, 17));

    const [arrayCard4, setArrayCard4] = useState(arrayAllCards.slice(17, 22));


    const [selected, setSelected] = useState([]);

    const [minHeight, setMinHeight] = useState(true);

    let selectedCardsFirstWay = React.useRef([]);
    let selectedCardsSecondWay = React.useRef([]);


    useEffect(() => {
        scrollTo()
    }, [selected]);

    const [currentCard, setCurrentCard] = useState();


    const selectCardsTypeWay = (item, array) => {
        scrollTo()

        ReactGA.event({
            category: 'Escolher carta - caminhos',
            action: item,
            label: item
        });

        if (array === 1) {
            setArrayCard1(arrayCard1.filter(card => item !== card))
        }

        if (array === 2) {
            setArrayCard2(arrayCard2.filter(card => item !== card))
        }

        if (array === 3) {
            setArrayCard3(arrayCard3.filter(card => item !== card))
        }

        if (array === 4) {
            setArrayCard4(arrayCard4.filter(card => item !== card))
        }

        animationWaysType(item)

    }

    const selecCard = (item, array, type = 1) => {
        scrollTo()
        ReactGA.event({
            category: 'Escolher carta - caminho especifica',
            action: item,
            label: item
        });

        if (array === 1) {
            setArrayCard1(arrayCard1.filter(card => item !== card))
        }

        if (array === 2) {
            setArrayCard2(arrayCard2.filter(card => item !== card))
        }

        if (array === 3) {
            setArrayCard3(arrayCard3.filter(card => item !== card))
        }

        if (array === 4) {
            setArrayCard4(arrayCard4.filter(card => item !== card))
        }

        animation(item)
    }

    const [animateDown, setAnimateDown] = useState(false)

    const animation = (item) => {

        setTimeout(() => {
            setCurrentCard(item)
        }, 300);


        setTimeout(() => {
            setAnimateDown(true)
        }, 2300);

        setTimeout(() => {
            setCurrentCard(null)
            setAnimateDown(false)
        }, 3200);

        setTimeout(() => {
            const newArray = [...selected, item];
            setSelected(newArray);
            if (selected.length === 2) {
                setArrayCard1([])
                setArrayCard2([])
                setArrayCard3([])
                setArrayCard4([])
                setMinHeight(false)
                setTimeout(() => {
                    navigate(`/resultado?type=${query.get('type')}&rock=${query.get("rock")}&cards=${selected.join(',')},${item}`.replaceAll(` `, `-`).toLowerCase());
                }, 2400);
            }
        }, 3500);

    }

    const animationWaysType = (item) => {

        setTimeout(() => {
            setCurrentCard(item)
        }, 300);

        setTimeout(() => {
            setAnimateDown(true)
        }, 2300);

        setTimeout(() => {
            setCurrentCard(null)
            setAnimateDown(false)
            if (selectedCardsFirstWay.current.length < 3) {
                selectedCardsFirstWay.current = [...selectedCardsFirstWay.current, item]

            } else if (selectedCardsSecondWay.current.length < 3) {

                selectedCardsSecondWay.current = [...selectedCardsSecondWay.current, item]
            }
        }, 3000);


        setTimeout(() => {
            if (selectedCardsFirstWay.current.length == 3) setAnimationClassFirstWay(true)
            if (selectedCardsSecondWay.current.length == 3) setAnimationClassSecondtWay(true)
        }, 3100);

        setTimeout(() => {

            if (selectedCardsFirstWay.current.length === 3 && selectedCardsSecondWay.current.length === 3) {
                setArrayCard1([])
                setArrayCard2([])
                setArrayCard3([])
                setArrayCard4([])
                setMinHeight(false)
                setTimeout(() => {
                    navigate(`/resultado?type=${query.get('type')}&rock=${query.get("rock")}&cards=${selectedCardsFirstWay.current.join(',').replaceAll(` `, `-`).toLowerCase() + ',' + selectedCardsSecondWay.current.join(',').replaceAll(` `, `-`).toLowerCase()}&q1=${query.get("q1")}&q2=${query.get("q2")}`);
                }, 2700);
            }
        }, 3500);

    }

    useEffect(() => {
        setTimeout(() => {
            setShakeCards(false)
        }, 2000);
    })

    return (
        <div className="fade-in">
            <header className="header">
                <a className="btn-back" onClick={() => navigate(-1)}>
                    <img src={BackButton} alt="img-back" />
                </a>
                <img src={Logo} onClick={() => navigate('/')} alt="logo" />
            </header>
            <section className="section-1">
                {shakeCards ? <p className="title shake">Embaralhando as cartas...</p> : (<>   <p className="steps">Passo 2 de 3</p>
                    {query.get('type') == 1 && <p className="title">Agora, escolha 3 <br /> cartas do baralho</p>}
                    {query.get('type') == 0 && <p className="title">Agora, escolha 3 <br />  cartas do baralho <br />  mentalizando o <br /> {selectedCardsFirstWay.current.length < 3 ? 'primeiro' : 'segundo '} caminho</p>}</>)}

                <p ref={refScroll} className="desc">Importante: Mentalize a questão ao  <br />selecionar as cartas.</p>

                {query.get('type') == 0 && <p className="desc way"><strong> {selectedCardsFirstWay.current.length < 3 ? 'primeiro' : 'segundo '} caminho </strong></p>}
                <p className="desc" style={{ maxWidth: '280px', fontStyle: 'italic' }}>“{query.get('type') == 0 && selectedCardsFirstWay.current.length < 3 ? query.get('q1') : query.get('q2')}“</p>

                <div style={{ position: 'relative' }}>

                    <div className="current-card" style={{
                        opacity: currentCard ? 1 : 0,
                        transition: "opacity 0.5s",
                        visibility: !currentCard ? "hidden" : "visible",
                    }}>
                        {currentCard && <img className={`card-img current ${animateDown ? 'animate-down' : ''}`} src={require(`../../assets/${currentCard?.toUpperCase()}.png`)} />}
                    </div>
                </div>


                <div className="cards" style={{ opacity: currentCard ? 0.2 : 1, minHeight: minHeight ? '350px' : '' }} >
                    <div className="list-card ">
                        {arrayCard1.map(item => (
                            <img className="card-img animation" title={`carta-${item}`} onClick={() => {

                                if (query.get('type') == 1) {
                                    selected.length <= 2 && selecCard(item, 1)
                                } else {
                                    selectCardsTypeWay(item, 1)
                                }

                            }} src={CardImg}></img>
                        ))}
                    </div>
                    <div className="list-card down">
                        {arrayCard2.map(item => (
                            <img className="card-img animation" title={`carta-${item}`} onClick={() => {
                                if (query.get('type') == 1) {
                                    selected.length <= 2 && selecCard(item, 2)
                                } else {
                                    selectCardsTypeWay(item, 2)
                                }
                            }} src={CardImg}></img>
                        ))}
                    </div>
                    <div className="list-card down center">
                        {arrayCard3.map(item => (
                            <img className="card-img animation" title={`carta-${item}`} onClick={() => {
                                if (query.get('type') == 1) {
                                    selected.length <= 2 && selecCard(item, 3)
                                } else {
                                    selectCardsTypeWay(item, 3)
                                }
                            }} src={CardImg}></img>
                        ))}
                    </div>
                    <div className="list-card down center">
                        {arrayCard4.map(item => (
                            <img className="card-img animation" title={`carta-${item}`} onClick={() => {
                                if (query.get('type') == 1) {
                                    selected.length <= 2 && selecCard(item, 4)
                                } else {
                                    selectCardsTypeWay(item, 4)
                                }
                            }} src={CardImg}></img>
                        ))}
                    </div>
                </div>

                <div className="container-cards">
                    {query.get('type') == 1 && <div className="selected-cards">
                        {selected.map(item => (<img className="card-img small" src={require(`../../assets/${item}.png`)}></img>))}
                    </div>}

                    <div className="selected-card-way">
                        {query.get('type') == 0 && selectedCardsSecondWay.current.length == 3 && <div className="box-way-cards">
                            <p className="desc way"><strong> segundo caminho </strong></p>
                            <p className="desc" style={{ maxWidth: '185px', textAlign: 'left', fontSize: '12px' }}>{query.get('q2')}</p>
                        </div>}

                        {query.get('type') == 0 && selectedCardsSecondWay.current.length <= 3 && <div className={`selected-cards ${animationClassSecondWay ? 'animation-small' : ''}`}>
                            {selectedCardsSecondWay.current.map(item => (<img className={`card-img small  ${animationClassSecondWay ? 'animation-small' : ''}`} src={require(`../../assets/${item}.png`)}></img>))}
                        </div>}
                    </div>

                    <div className="selected-card-way">
                        {query.get('type') == 0 && selectedCardsFirstWay.current.length == 3 && <div className="box-way-cards">
                            <p className="desc way"><strong> primeiro caminho </strong></p>
                            <p className="desc" style={{ maxWidth: '185px', textAlign: 'left', fontSize: '12px' }}>{query.get('q1')}</p>
                        </div>}

                        {query.get('type') == 0 && selectedCardsFirstWay.current.length <= 3 && <div className={`selected-cards ${animationClassFirstWay ? 'animation-small' : ''}`}>
                            {selectedCardsFirstWay.current.map(item => (<img className={`card-img small  ${animationClassFirstWay ? 'animation-small' : ''}`} src={require(`../../assets/${item}.png`)}></img>))}
                        </div>}
                    </div>

                    <a className="box-rock green">
                        <img className="rock-img" src={arrayRocks[query.get("rock")].img}></img>
                        <div className="col">
                            <p className="title-rock">{arrayRocks[query.get("rock")].title}</p>
                            <p className="desc-rock">{arrayRocks[query.get("rock")].desc}</p>
                        </div>
                    </a>
                </div>
            </section>
            <Footer />
        </div>);
}
export default ChooseCard;