import React from 'react';
import './index.scss'
import ReactGA from 'react-ga4';
import Logo from '../../assets/Logo-footer.png'
import ArrowFooter from '../../assets/Arrow-footer.png'

const Footer = () => {

    return (
        <footer className="footer">
            <img className="logo-footer" src={Logo}></img>
            <div className="col-footer">
                <p className="text-footer" >O Tarot do Conhecimento é um projeto recifense experimental.</p>
                <a href="mailto:taarotdoautoconhecimento@gmail.com" onClick={() => {
                    ReactGA.event({
                        category: 'Footer',
                        action: 'Entrar em contato',
                        label: 'Entrar em contato'
                    });
                }} className="link-footer">Entre em contato
                    <img src={ArrowFooter}></img>
                </a>
                <small>2022, Recife</small>
            </div>
        </footer>
    )
}

export default Footer;