import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import ReactGA from 'react-ga4';

import './index.scss'

import Logo from '../../assets/logo-final.png'
import BackButton from '../../assets/back-btn.png'

import IconQuartzoRosa from '../../assets/IconQuartzoRosa.png'
import IconCalcita from '../../assets/CalcitaVerde.png'
import IconCitrino from '../../assets/Citrino.png'
import IconSelenita from '../../assets/Selenita.png'
import Footer from '../../components/Footer';




const arrayRocks = [
    {
        img: IconQuartzoRosa,
        title: 'Quartzo rosa',
        desc: 'Pedra do amor',
        id: 0,
    },
    {
        img: IconSelenita,
        title: 'Selenita',
        desc: 'Pedra da conexão com guias espirituais e espiritualidade',
        id: 1
    },
    {
        img: IconCalcita,
        title: 'Calcita verde',
        desc: 'Pedra da paz espiritual e cura emocional',
        id: 2
    },
    {
        img: IconCitrino,
        title: 'Citrino',
        desc: 'Pedra da prosperidade e sucesso',
        id: 3
    }
]

const SetWay = () => {

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    const navigate = useNavigate();


    const [selected, setSelected] = useState([]);

    const [questionOne, setQuestionOne] = useState("");

    const [questionTwo, setQuestionTwo] = useState("");

    useEffect(() => {

    }, [selected]);

    // @ts-ignore
    const rock = query.get("rock") || 0;

    return (
        <div className="fade-in">
            <header className="header">
                <a className="btn-back" onClick={() => navigate(-1)}>
                    <img src={BackButton} alt="img-back" />
                </a>
                <img src={Logo} onClick={() => navigate('/')} alt="logo" />
            </header>
            <section className="section-1">
                <p className="steps">Passo 1 de 3</p>
                <p className="title">Defina as <br /> alternativas de <br /> caminhos possíveis</p>
                <p className="desc" style={{ marginBottom: '5px' }}>Você pode definir até dois <br /> caminhos diferentes.</p>

                <div className="box-way">
                    <p className="desc box-way-desc">Descreva o <strong>primeiro</strong> caminho </p>
                    <textarea id="w3review" name="w3review" rows={4} value={questionOne} onChange={(item) => setQuestionOne(item.target.value)} placeholder="Ex: Será que devo aceitar esse novo emprego?" />
                    <p className="desc box-way-desc">Descreva o <strong>segundo</strong> caminho </p>
                    <textarea rows={4} value={questionTwo} onChange={(item) => setQuestionTwo(item.target.value)} placeholder="Ex: O que acontecerá se não aceitar esse novo emprego?" />
                </div>

                <a className="box-rock green">
                    <img className="rock-img" src={arrayRocks[rock].img}></img>
                    <div className="col">
                        <p className="title-rock">{arrayRocks[rock].title}</p>
                        <p className="desc-rock">{arrayRocks[rock].desc}</p>
                    </div>
                </a>

                <Link className="btn-next" to={`/escolha-carta?type=${query.get("type")}&rock=${rock}&q1=${questionOne}&q2=${questionTwo}`}
                    onClick={() => {
                        ReactGA.event({
                            category: 'Escolher carta',
                            action: `/escolha-carta?type=${query.get("type")}&rock=${rock}&q1=${questionOne}&q2=${questionTwo}`,
                            label: `/escolha-carta?type=${query.get("type")}&rock=${rock}&q1=${questionOne}&q2=${questionTwo}`
                        });
                    }}
                >
                    Próxima
                    <img className="img-next-btn" src={require('../../assets/ArrowRight.png')}></img>
                </Link>
            </section>
            <Footer />
        </div>);
}
export default SetWay;