import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Home from "./pages/Home/index";
import ShowResult from "./pages/ShowResult/index";
import ChooseRock from "./pages/ChooseRock/index";
import ChooseCard from "./pages/ChooseCard/index";
import ChooseType from "./pages/ChooseType/index";
import SetWay from "./pages/SetWay/index";


import ReactGA from "react-ga4";
import { useEffect } from "react";

const TRACKING_ID = "G-Q3JECMN8V3"; // OUR_TRACKING_ID

function App() {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<SetWay />} path="definir-caminhos" />
        <Route element={<ShowResult />} path="resultado" />
        <Route element={<ChooseRock />} path="escolha-pedra" />
        <Route element={<ChooseCard />} path="escolha-carta" />
        <Route element={<ChooseType />} path="escolha-tipo" />
        <Route element={<Home />} path="/" />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
